<script setup lang="ts">
import {
  CmsElementImage,
  CmsElementManufacturerLogo,
  useCmsElementImage,
} from '@shopware-pwa/composables-next';

const props = defineProps<{
  content: CmsElementImage | CmsElementManufacturerLogo | any;
}>();

const {
  containerStyle,
  displayMode,
  imageContainerAttrs,
  imageAttrs,
  imageLink,
} = useCmsElementImage(props.content);
</script>
<template>
  <!-- TODO: using a tag only works with externalLink, need to improve this element to deal with both internalLink & externalLink -->
  <component
    :is="imageLink.url ? 'a' : 'div'"
    v-if="imageAttrs.src"
    class="cms-element-image relative"
    :style="containerStyle"
    v-bind="imageContainerAttrs"
  >
    <img
      :class="{
        'h-full w-full': true,
        'absolute inset-0': ['cover', 'stretch'].includes(displayMode),
        'object-cover': displayMode === 'cover',
      }"
      v-bind="imageAttrs"
      :alt="imageAttrs.alt || ''"
      :title="content.data?.media?.title || ''"
      loading="lazy"
    />
  </component>
</template>

<style lang="scss" scoped>
.w-full {
  width: 100%;
}
</style>
